import {pushToDatalayer} from "../utils/tracking/gtmTracking";

// Events : 1] Click
//          2] Add value
// Journey Types : 1] Insta
//                 2] AMC
//                 3] EW
//                 3] SDP
//                 3] SP


export const getStarted = (journeyType, categories) => {
    if(categories.length>1){
        if(categories[0].includes('AC')){
            categories = ['Air Conditioner']
        }else if(categories[0].includes('Water Purifier')){
            categories = ['Water Purifier']
        }
    }
    for(let device in categories ){
        try{
            const payload = {
                event: "Get Started Button",
                action: "Click",
                category: "Booking Flow",
                label: "Get Started Button - Click",
                device_category: categories[device],
                journey_type: journeyType,
                journey_starts_from: localStorage.getItem('journey_starts_from')
            }
            pushToDatalayer("", payload)
        }catch{
            console.log('error while pushing to datalater')
        }
    }
    
}

export const devicePriceTrigger = (journeyType, deviceCategory) =>{
    try{
        const payload = {
        event: "Device Price Value",
        action: "Add value",
        category: "Booking Flow",
        label:"Device Price Value - Add Values",
        device_category: deviceCategory,
        journey_type: journeyType,
        journey_starts_from: localStorage.getItem('journey_starts_from')
        }
        pushToDatalayer("", payload)
    }catch{
        console.log('error while pushing to datalater')
    }

}
export const viewPlanClick = (journeyType, deviceCategory) =>{
    try{
        const payload = {
            event: "View Plan Button",
            category: "Booking Flow",
            action: "Click",
            label: "View Plan Button - Click",
            device_category: deviceCategory,
            journey_type: journeyType,
            journey_starts_from: localStorage.getItem('journey_starts_from')
        }
        pushToDatalayer("", payload)
    }catch{
        console.log('error while pushing to datalater')
    }

}

export const selectPlanClick = (journeyType, deviceCategory) =>{
    try{
    const payload = {
        event: "Plan and Year Button",
        category: "Booking Flow",
        action: "Click",
        label: "Plan and Year Button - Click",
        device_category: deviceCategory,
        journey_type: journeyType,
        journey_starts_from: localStorage.getItem('journey_starts_from')
    }
    pushToDatalayer("", payload)
    }catch{
        console.log('error while pushing to datalater')
    }
}

export const addToCartClick = (journeyType, deviceCategory) => {
    try{
        const payload = {
            event: "Add to Cart Button",
            action: "Click",
            category: "Booking Flow",
            label: "Add to Cart Button - Click",
            device_category: deviceCategory,
            journey_type: journeyType,
            journey_starts_from: localStorage.getItem('journey_starts_from')
        }
        pushToDatalayer("", payload)
    }catch{
        console.log('error while pushing to datalater')
    }
}

export const buyNowClick = (journeyType, deviceCategory) => {
    try{
        const payload = {
            event: "Buy Now Button",
            action: "Click",
            category: "Booking Flow",
            label: "Buy Now Button - Click",
            device_category: deviceCategory,
            journey_type: journeyType,
            journey_starts_from: localStorage.getItem('journey_starts_from')
        }
        pushToDatalayer("", payload)
    }catch{
        console.log('error while pushing to datalater')
    }
}

export const requestCallbackClick = (journeyType, deviceCategory) => {
  try {
    const payload = {
      action: 'Click',
      category: 'Booking Flow',
      label: "Request Callback Button - Click",
      device_category: deviceCategory,
      journey_type: journeyType,
      journey_starts_from: localStorage.getItem('journey_starts_from')
    }
    pushToDatalayer("Request Callback Button", payload)
  } catch {
    console.log('error while pushing to datalater')
  }
}

// Need to check checkoutClick either modify or remove
// export const checkoutClick = (plan_type) => {
//     if(plan_type == 'protection-plan'){
//         plan_type = 'Protection Plan'
//     }else if(plan_type == 'amc'){
//         plan_type = 'AMC'
//     }else if(plan_type == 'insta-repair'){
//         plan_type = 'Insta Repair'
//     }
//     try{
//         const payload = {
//             event: "Checkout Button",
//             action: "Click",
//             category: plan_type + " Booking Flow",
//             section: "Checkout",
//             label: "Checkout Button - Click"}
//         pushToDatalayer("", payload)
//     }catch{
//         console.log('error while pushing to datalater')
//     }
// }

export const selectDeviceTypeClick = (journeyType, deviceCategory) => {
    try{
        const payload = {
            event: "Device Type",
            action: "Click",
            category: "Booking Flow",
            label: "Device Type - Click",
            device_category: deviceCategory,
            journey_type: journeyType,
            journey_starts_from: localStorage.getItem('journey_starts_from')
        }
        pushToDatalayer("", payload)
    }catch{
        console.log('error while pushing to datalater')
    }
}

export const selectAcCapacityClick = (journeyType, deviceCategory) => {
    try{
        const payload = {
            event: "Device Capacity",
            action: "Click",
            category: "Booking Flow",
            label: "Device Capacity - Click",
            device_category: deviceCategory,
            journey_type: journeyType,
            journey_starts_from: localStorage.getItem('journey_starts_from')
        }
        pushToDatalayer("", payload)
    }catch{
        console.log('error while pushing to datalater')
    }
}

export const selectAcBrandClick = (journeyType, deviceCategory) => {
    try{
        const payload = {
            event: "Device Brand",
            action: "Click",
            category: "Booking Flow",
            label: "Device Brand - Click",
            device_category: deviceCategory,
            journey_type: journeyType,
            journey_starts_from: localStorage.getItem('journey_starts_from')
        }
        pushToDatalayer("", payload)
    }catch{
        console.log('error while pushing to datalater')
    }
}

export const amcAcPlanTypeButton = (type:string,journeyType, deviceCategory) => {
    try{
        const payload = {
            event: `${type} Price Button`,
            action: "Click",
            category: "Booking Flow",
            label:`${type} Price Button - Click`,
            device_category: deviceCategory,
            journey_type: journeyType,
            journey_starts_from: localStorage.getItem('journey_starts_from')
            }
        pushToDatalayer("", payload)
    }catch{
        console.log('error while pushing to datalater')
    }
}

// export const advancedAMCButton = () => {
//     try{
//         const payload = {
//             event: "AMC AC Advanced AMC Price Button",
//             action: "Click",
//             category: "AMC Booking Flow",
//             section: "Advanced AMC Price Button",
//             label: "AMC AC Advanced AMC Price Button - Click"
//             }
//         pushToDatalayer("", payload)
//     }catch{
//         console.log('error while pushing to datalater')
//     }
// }

// export const comprehensiveAMCButton = () => {
//     try{
//         const payload = {
//             event: "AMC AC Comprehensive AMC Price Tab Button",
//             action: "Click",
//             category: "AMC Booking Flow",
//             section: "Comprehensive AMC Price Button",
//             label: "AMC AC Comprehensive AMC Price Tab Button - Click"
//             }
//         pushToDatalayer("", payload)
//     }catch{
//         console.log('error while pushing to datalater')
//     }
// }

export const selectWpTypeTrigger = (journeyType, deviceCategory) => {
    try{
        const payload = {
            event: "Device Type",
            action: "Click",
            category: "Booking Flow",
            label:"Device Type - Click",
            device_category: deviceCategory,
            journey_type: journeyType,
            journey_starts_from: localStorage.getItem('journey_starts_from')
            }
        pushToDatalayer("", payload)
    }catch{
        console.log('error while pushing to datalater')
    }

}

export const selectWpBrand = (journeyType, deviceCategory) => {
    try{
        const payload = {
            event: "Device Brand",
            action: "Click",
            category: "Booking Flow",
            label:"Device Brand - Click",
            device_category: deviceCategory,
            journey_type: journeyType,
            journey_starts_from: localStorage.getItem('journey_starts_from')
            }
        pushToDatalayer("", payload)
    }catch{
        console.log('error while pushing to datalater')
    }
}

// Not Using any where need to check

// export const selectDeviceType = (device) => {
//     try{
//         const payload = {
//             section:"Select Device Type",
//             action:"Click",
//             category:"Insta Repair Booking Flow",
//             event:"Insta Repair Device Type",
//             label:"Insta Repair Device Type - Click"
//         }
//         pushToDatalayer("", payload)
//     }catch{
//         console.log('error while pushing to datalater')
//     }
// }


export const selectPlanType = (journeyType, deviceCategory,categoryType) => {
    try{
        if(['Basic Chimney','Island Chimney','Window AC','Split AC', 'UV Water Purifier', 'RO Water Purifier'].includes(deviceCategory)){
            const payload = {
                event:"Insta Repair Plan Type",
                action:"Click",
                category:"Booking Flow",
                label:"Insta Repair Plan Type - Click",
                device_category: categoryType,
                journey_type: journeyType,
                journey_starts_from: localStorage.getItem('journey_starts_from')
            }
            pushToDatalayer("", payload)
        }else{
            const payload = {
                event:"Insta Repair Plan Type",
                action:"Click",
                category:"Booking Flow",
                label:"Insta Repair Plan Type - Click",
                device_category: deviceCategory,
                journey_type: journeyType,
                journey_starts_from: localStorage.getItem('journey_starts_from')
            }
            pushToDatalayer("", payload)
        }
        
    }catch{
        console.log('error while pushing to datalater')
    }
}

export const goToCart = (journeyType, deviceCategory) => {
    try{
        const payload = {
            event:"Insta Repair Go to Cart Button",
            action:"Click",
            category:"Booking Flow",
            label:"Insta Repair Go to Cart Button - Click",
            device_category: deviceCategory,
            journey_type: journeyType,
            journey_starts_from: localStorage.getItem('journey_starts_from')
        }
        pushToDatalayer("", payload)
    }catch{
        console.log('error while pushing to datalater')
    }
}

export const callbackFormName = (journeyType, deviceCategory) =>{
    try{
        const payload = {
          action: "Add value",
          category: "Booking Flow",
          label:"Full Name - Add Values",
          device_category: deviceCategory,
          journey_type: journeyType,
          journey_starts_from: localStorage.getItem('journey_starts_from')
        }
        pushToDatalayer("Customer Name", payload)
    }catch{
        console.log('error while pushing to datalater')
    }

}

export const callbackFormPhone = (journeyType, deviceCategory) =>{
    try{
        const payload = {
          action: "Add value",
          category: "Booking Flow",
          label:"Phone Number - Add Values",
          device_category: deviceCategory,
          journey_type: journeyType,
          journey_starts_from: localStorage.getItem('journey_starts_from')
        }
        pushToDatalayer("Phone Number", payload)
    }catch{
        console.log('error while pushing to datalater')
    }

}

export const callbackFormCustomCity = (journeyType, deviceCategory) =>{
    try{
        const payload = {
          action: "Add value",
          category: "Booking Flow",
          label:"Custom City - Add Values",
          device_category: deviceCategory,
          journey_type: journeyType,
          journey_starts_from: localStorage.getItem('journey_starts_from')
        }
        pushToDatalayer("Custom City", payload)
    }catch{
        console.log('error while pushing to datalater')
    }

}

export const callbackFormCity = (journeyType, deviceCategory) => {
    try{
        const payload = {
            action: "Click",
            category: "Booking Flow",
            label: "City - Select",
            device_category: deviceCategory,
            journey_type: journeyType,
            journey_starts_from: localStorage.getItem('journey_starts_from')
        }
        pushToDatalayer("City", payload)
    }catch{
        console.log('error while pushing to datalater')
    }
}

export const callbackButtonClick = (journeyType, deviceCategory, buttonName) => {
  try {
    const payload = {
      action: "Click",
      category: "Booking Flow",
      label: `${buttonName} - Click`,
      device_category: deviceCategory,
      journey_type: journeyType,
      journey_starts_from: localStorage.getItem('journey_starts_from')
    }
    pushToDatalayer(`${buttonName} Button`, payload)
  } catch {
    console.log('error while pushing to datalater')
  }
}

import { PushToTracker  } from "../../utils/tracking";

// data contains - { }
export const locationSelectionPopup = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Location Selection Popup', "payload":{...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { page_link }
export const getStartedClicked = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Get Started Clicked', "payload":{...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { category_name }
export const subcategorySelected = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Subcategory Selected', "payload":{...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { sku_name, sku_price, sku_position }
export const skuCardExpanded = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'SKU Card Expanded', "payload":{...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { sku_name, sku_price, sku_position }
export const addToCartClicked = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Add To Cart Clicked', "payload":{...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { sku_name, sku_price, sku_position, curr_quantity }
export const addQuantityClicked = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Add Quantity Clicked', "payload":{...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { sku_name, sku_price, sku_position, curr_quantity }
export const reduceQuantityClicked = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Reduce Quantity Clicked', "payload":{...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { page_link, cart_items, cart_amount }
export const goToCartClicked = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Go To Cart Clicked', "payload":{...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { page_link}
export const TestimonialReadMoreClicked = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":"Testimonial Read More Clicked", "payload":{...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { page_link }
export const commonProblemsReadMoreClicked = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Common Problems Read More Clicked', "payload":{...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { page_link, faq_question, current_city }
export const faqClicked = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'FAQ Clicked', "payload":{...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { card_title, card_position, cta_name, cta_url }
export const crosssellCarouselClicked = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Cross-sell Carousel Clicked', "payload":{...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { blog_title, blog_url }
export const blogSectionClicked = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event": 'Blog Section Clicked', "payload":{...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { }
export const viewPlanClickedSegment = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event": 'View Plan Clicked', "payload":{...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - {  }
export const locationChangeClicked = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'location Change Clicked', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { click_text, breadcrumb }
export const breadCrumbClickSegment = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Breadcrumb Click', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - {  }
export const readFaqLinkClick = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Read FAQ Link Click', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - {  }
export const planPageDeviceSearch = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Plan Page Device Search', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - {  }
export const planPageDeviceItemClick = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Plan Page Device Item Clicked', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - {  }
export const exploreSerivecsKitchenHome = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Explore Serivecs Kitchen & Home', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}


// data contains - {  }
export const devicePriceEdit = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Device Price Edit', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - {  }
export const compareTableBuyNowLink = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Compare Table Buy Now Link', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - {  }
export const requestCallbackClicked = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Request Callback Clicked', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - {  }
export const requestCallbackSubmitClicked = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Request Callback Submit Form', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - {  }
export const addMoreDevice = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Add More Device', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - {  }
export const addMoreDevicePlanClick = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Add More Device Plan Click', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - {  }
export const addMoreAddToCartClick = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Add More Add To Cart Click', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - {  }
export const addAdditonalDeviceClick = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Add Additonal Device Click', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - {  }
export const addAdditonalDeviceTypeClick = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Add Additional Device Type Click', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - {  }
export const addAdditonalDeviceViewPlanClick = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Add Additonal Device View Plan Click', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - {  }
export const productDetailScreenViewed = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Product Detail - Screen Viewed', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - {  }
export const abExperiment = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'AB Experiment', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - {  }
export const cityChangePopupOpen = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'City Change Popup Open', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - {  }
export const cityChangePopupClose = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'City Change Popup Close', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}